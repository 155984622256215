import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as courseActions from "store/modules/course";
import * as guideActions from "store/modules/guide";
import {
  PageHeader,
  Typography,
  Skeleton,
  Collapse,
  Icon,
  Radio,
  Button,
  Input,
  message,
  Popconfirm,
} from "antd";
import ClassTable from "../dashboard/component/ClassTable";
import AccountApis from "../../../../utils/accountApis";
import { ConstantsContext } from "utils/ConstantsContext";

const { Title } = Typography;

class ClassroomListPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      filterType: null,
      // filterType:
      todayOnly: false,
      finishedOnly: false,
      showDeleted: false,
      // OR
      searchName: null,
      selectedRowKeys: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions, CourseActions, GuideActions } = this.props;
    console.log("mount dashboard: ", user);

    if (user && user.orgId) {
      ClassroomActions.getClassroomList(user.userId, user.type);
      // CourseActions.getCourseList();
      // GuideActions.getGuideList(user.orgId);
      this.getOrgInfo(user.orgId);
    }
  }

  componentDidUpdate(nextProps) {
    const { user, ClassroomActions, CourseActions, GuideActions } = this.props;
    if (this.props.user && this.props.user.type !== nextProps.user.type) {
      console.log("receive props: ", nextProps);
      ClassroomActions.getClassroomList(user.orgId);
      // CourseActions.getCourseList();
      // GuideActions.getGuideList(user.orgId);
      this.getOrgInfo(user.orgId);
    }
  }

  getOrgInfo = (orgId) => {
    AccountApis.getOrgInfo(orgId).then((res) => {
      console.log("org info: ", res);
    });
  };

  render() {
    const { classroomList, courseList, guideList, user, userPermission } =
      this.props;
    const { filterType, todayOnly, finishedOnly, showDeleted, searchName } =
      this.state;

    const { Panel } = Collapse;

    const customPanelStyle = {
      background: "#fff",
      borderRadius: 4,
      marginBottom: 24,
      // border: 0,
      overflow: "hidden",
    };

    console.log("render dashboard: ", this.props.auth.user);
    console.log("classroom list: ", classroomList);
    console.log("course list: ", courseList);
    console.log("guide lsit: ", guideList);

    console.log("today only: ", todayOnly);
    console.log("finished only: ", finishedOnly);
    console.log("show deleted: ", showDeleted);

    return (
      <Skeleton loading={this.props.pending} active>
        <PageHeader
          key="1"
          title={
            <Title level={2} style={{ marginBottom: 0 }}>
              {`${this.context.className} 목록`}
            </Title>
          }
          extra={[
            <Radio.Group
              defaultValue=""
              value={filterType}
              onChange={(e) => {
                console.log("### radio:", e.target.value);
                this.setState({
                  filterType: e.target.value,
                  todayOnly: false,
                  finishedOnly: false,
                  showDeleted: false,
                  searchName: null,
                  [e.target.value]: true,
                });
              }}
            >
              <Radio value={"todayOnly"}>오늘 수업만 보기</Radio>
              <Radio value={"finishedOnly"}>
                종료된 {this.context.className}만 보기
              </Radio>
              <Radio value={"showDeleted"}>
                삭제된 {this.context.className}만 보기
              </Radio>
            </Radio.Group>,
            <Input.Search
              placeholder="클래스 이름"
              value={searchName}
              onClick={() =>
                this.setState({
                  filterType: null,
                  todayOnly: false,
                  finishedOnly: false,
                  showDeleted: false,
                })
              }
              onChange={(e) =>
                this.setState({
                  filterType: null,
                  todayOnly: false,
                  finishedOnly: false,
                  showDeleted: false,
                  searchName: e.target.value,
                })
              }
              onSearch={(value) => {}}
              style={{ width: "200px", marginLeft: "8" }}
            />,
            <span key="1">
              {(userPermission === "master" ||
                (userPermission && userPermission.classCreateDelete)) && (
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.props.history.push("/admin/class/add")}
                >
                  {`${this.context.className} 생성하기`}
                  <Icon type="plus-circle" />
                </Button>
              )}
            </span>,
            <Popconfirm
              key="2"
              title={`선택한 ${this.context.className}를 삭제 하시겠습니까?`}
              disabled={
                this.state.selectedRowKeys.length === 0 || this.state.loading
              }
              onConfirm={async () => {
                const { selectedRowKeys: classroomIds } = this.state;
                console.log("### delete classroom: ", classroomIds);

                this.setState({
                  loading: true,
                });
                for (let index = 0; index < classroomIds.length; index++) {
                  const classroomId = classroomIds[index];
                  try {
                    await AccountApis.changeClassroomState(
                      user.orgId,
                      classroomId,
                      "deleted"
                    );
                  } catch (e) {
                    message.error("삭제 실패");
                    this.setState({
                      loading: false,
                    });
                    return;
                  }
                }
                message.success("삭제 완료");
                // load classroom list
                this.setState(
                  {
                    loading: false,
                  },
                  () => {
                    this.props.ClassroomActions.getClassroomList(user.orgId);
                  }
                );
              }}
              onCancel={() => message.warning("삭제 취소")}
              okText="확인"
              placement="bottomRight"
              cancelText="취소"
            >
              {(userPermission === "master" ||
                (userPermission && userPermission.classCreateDelete)) && (
                <Button
                  type="danger"
                  disabled={this.state.selectedRowKeys.length === 0}
                >
                  {`${this.context.className} 삭제`}
                  <Icon type="delete" />
                </Button>
              )}
            </Popconfirm>,
          ]}
        />
        <div className="admin-page-content">
          <ClassTable
            classroomList={classroomList}
            todayOnly={todayOnly}
            finishedOnly={finishedOnly}
            showDeleted={showDeleted}
            searchName={searchName}
            orgType={this.props.orgType}
            hasGuidePermission={
              userPermission === "master" ||
              (userPermission && userPermission.guideCreateDelete)
            }
            selectedRowKeys={this.state.selectedRowKeys}
            onSelectedRowKeysChange={(selectedRowKeys) =>
              this.setState({ selectedRowKeys })
            }
          />
        </div>
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, classroom, course, guide }) => ({
    pending: auth.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    orgType: auth.user.orgType,
    userPermission: auth.user.type === "org" ? "master" : auth.user.permission,
    classroomList: classroom.classroomList,
    // courseList: course.courseList,
    // guideList: guide.guideList
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
    CourseActions: bindActionCreators(courseActions, dispatch),
    GuideActions: bindActionCreators(guideActions, dispatch),
  })
)(ClassroomListPage);
