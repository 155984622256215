import {
  Calendar,
  Col,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Tag,
  Typography,
  message,
} from "antd";
import Title from "antd/lib/skeleton/Title";
import moment from "moment";
import React, { useEffect } from "react";
import Axios from "utils/request";
import styles from "./calendar.module.scss";

const CalendarPage = () => {
  const [holidays, setHolidays] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [year, setYear] = React.useState(moment().year());

  useEffect(() => {
    loadHolidays();

    return () => {};
  }, []);

  const loadHolidays = async () => {
    try {
      const response = await Axios.get(`/academy/common/v2/holidays`);
      console.log("holidays: ", response);
      const _holidays = {};
      response.forEach((element) => {
        const _key = moment(element.date).format("YYYY-MM");
        if (_holidays[_key]) {
          _holidays[_key].push(element.date);
        } else {
          _holidays[_key] = [element.date];
        }
      });
      // response.forEach((element) => {
      //   _holidays.push(element.date);
      // });
      setHolidays(_holidays);
      setLoading(false);
    } catch (error) {
      console.error("error:", error.response.data);
      setLoading(false);
      setError(error.response.data);
      message.error("휴강일을 불러오는데 실패했습니다.");
    }
  };

  const monthCellRender = (value) => {
    console.log("monthCellRender: ", holidays, value.format("YYYY-MM-DD"));
    // if holidays item startsWith value.format("YYYY-MM") then return custom data
    const _key = value.format("YYYY-MM");
    const weekColors = [
      "",
      "",
      "gold",
      "green",
      "blue",
      "magenta",
      "purple",
      "",
    ];
    if (holidays[_key]) {
      console.log("value: ", value.format("YYYY-MM-DD"));
      return (
        <div
          className="notes-month"
          style={{ display: "flex", gap: 4, flexWrap: "wrap" }}
        >
          {holidays[_key].map((item, index) => {
            return (
              <Tag
                key={`${item}`}
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                color={weekColors[moment(item).day()]}
              >
                {`${moment(item).format("DD")}일 (${moment(item).format(
                  "ddd"
                )})`}
              </Tag>
            );
          })}
        </div>
      );
    }
  };

  const headerRender = ({ value, type, onChange }) => {
    const start = 2024;
    const end = 2025;
    const yearOptions = [];
    for (let i = start; i <= end; i++) {
      yearOptions.push(
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>
      );
    }

    return (
      <div style={{ padding: 10, display: "flex", justifyContent: "flex-end" }}>
        <Select
          value={value.year()}
          onChange={(e) => {
            const newYear = parseInt(e, 10);
            const newValue = value.clone().year(newYear);
            onChange(newValue);
            setYear(newYear);
          }}
        >
          {yearOptions}
        </Select>
      </div>
    );
  };
  const weekColors = ["", "", "gold", "green", "blue", "magenta", "purple", ""];
  return (
    <Skeleton loading={loading} active>
      <PageHeader
        title={
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            {year}년 휴강일
          </Typography.Title>
        }
      />
      <div className="admin-page-content">
        <div className="new-calendar">
          <div
            style={{ padding: 10, display: "flex", justifyContent: "flex-end" }}
          >
            <Select
              value={year}
              onChange={(e) => {
                // const newYear = parseInt(e, 10);
                // const newValue = value.clone().year(newYear);
                // onChange(newValue);
                setYear(e);
              }}
            >
              <Select.Option value={2024}>{2024}</Select.Option>
              <Select.Option value={2025}>{2025}</Select.Option>
            </Select>
          </div>
          <Row>
            {/* 1월~6월 */}
            <Col span={12} style={{
              borderRight: "1px solid #f0f0f0",
            }}>
              {[1, 2, 3, 4, 5, 6].map((month) => {
                const _key = `${year}-${month.toString().padStart(2, "0")}`;
                return (
                  <div key={_key} className={styles.month}>
                    <h3>{month}월</h3>
                    <div className={styles.day}>
                      {holidays[_key]?.map((item, index) => {
                        return (
                          <Tag
                            key={`${item}`}
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                            color={weekColors[moment(item).day()]}
                          >
                            {`${moment(item).format("DD")}일 (${moment(
                              item
                            ).format("ddd")})`}
                          </Tag>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Col>
            {/* 6월~12월 */}
            <Col span={12}>
              {[7, 8, 9, 10, 11, 12].map((month) => {
                const _key = `${year}-${month.toString().padStart(2, "0")}`;
                return (
                  <div key={_key} className={styles.month}>
                    <h3>{month}월</h3>
                    <div className={styles.day}>
                      {holidays[_key]?.map((item, index) => {
                        return (
                          <Tag
                            key={`${item}`}
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                            color={weekColors[moment(item).day()]}
                          >
                            {`${moment(item).format("DD")}일 (${moment(
                              item
                            ).format("ddd")})`}
                          </Tag>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
        {/* <Calendar
          className={styles.calendar}
          // fullscreen={false}
          mode="year"
          // defaultValue={moment(holidays[0]?.date)}
          monthCellRender={monthCellRender}
          headerRender={headerRender}
        /> */}
        {error && (
          <div className="">
            <h3>Error</h3>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </div>
        )}
      </div>
    </Skeleton>
  );
};

export default CalendarPage;
